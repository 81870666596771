<template>
  <div class="developing">
    <div>
      <img src="@/assets/images/kfz.png" alt="">
      <div>
        <p>即将开放，敬请期待！</p>
        <!-- <span>我们正在加载更多新功能，敬请期待～</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { core } from '../config/pluginInit'
export default {
  name: "developing",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    core.index()
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
  .developing {
    // width: 100%;
    height: 700px;
    background-color: #fff;
    margin: 0 15px;
    border-radius: 18px;
    margin-bottom: 30px;
    margin-top: 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 150px;
      img {
        width: 570px;
        margin-right: 100px;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding-top: 0;
        p {
          font-size: 44px;
          font-weight: bold;
          color: #6474C7;
        }
        span {
          font-size: 28px;
          font-weight: bold;
          color: #333;
        }
      }
    }
  }
</style>

